@mixin display-align(
    $flexDirection,
    $justifyContent,
    $alignItems,
    $alignContent
) {
    display: flex;
    width: auto;
    flex-direction: $flexDirection;
    justify-content: $justifyContent;
    align-items: $alignItems;
    align-content: $alignContent;
}

.form-row {
    margin-bottom: 2rem;
}


.form-row-header {
    margin-bottom: 1rem;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
}

.org-legal-name {
    margin-top: 5rem;
}

.verify-text {
    display: inline-flex;
    padding-right: 1rem;
}

.verify-text-email {
    width: 25.5rem;
}

.verify-btn {
    display: inline-flex;
}

.info-dropdown {
    position: static;
}

.employer-registration-div-field-survey {
    @include display-align(column, space-evenly, null, null);
    .MuiSelect-select.MuiSelect-select {
        width: 100%;
    }
}

.employer-registration-form-container {
    @include display-align(column, center, center, center);
    z-index: 999;
}

.emp-registration-disclaimer-container {
    height: 11.5rem;
    @include display-align(column, space-evenly, null, null);
}

.emp-disclamer-info-container {
    height: 1rem;
    display: flex;
    flex-direction: row;
}

.emp-salesforce-content {
    display: none;
}

.register-error {
    color: var(--error-message-color);
    font-size: 1rem;
    margin-top: 0.5rem;
    max-width: fit-content;
    margin-bottom: 1rem;
}

.register-enter-otp {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.register-email-container {
    display: flex;
    height: 2.625rem;
    margin-bottom: 2rem;
}

.register-otp-input {
    border-radius: 0.5rem;
    width: 2.625rem !important;
    margin-right: 1rem;
    height: 2.625rem;
    border: 0.09375rem solid var(--text-input-default-border);
    font-size: 1rem;
    line-height: 1.375rem;
    color: var(--text-input-active-color);
}

.register-otp-input {
    &:disabled {
        border: 0.09375rem solid var(--text-input-disabled-border);
        color: var(--text-input-disabled-color);
    }
    &:focus {
        border: 0.09375rem solid var(--text-input-active-border);
        color: var(--text-input-actve-color);
        outline: none;
    }
}


.register-enter-otp {
    font-size: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 45rem) {
    .register-email-container {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .register-input {
        width: 90%;
    }

    .register-email {
        width: 90%;
    }

    .register-send-otp {
        margin-left: 0;
    }

    .register-verify-otp-container {
        display: flex;
        gap: 0.5rem;
        margin-top: 1rem;
    }
}

@media (max-width: 25rem) {
    .register-input {
        width: auto;
    }

    .register-email {
        width: auto;
        margin-right: 0;
    }
    .register-enter-otp {
        font-size: 0.875rem;
        line-height: 1rem;
    }

    .register-otp-input {
        font-size: 0.875rem;
        line-height: 1rem;
    }

    .register-error {
        font-size: 0.875rem;
        line-height: 1rem;
    }
}

.otp-verification-done {
    margin-bottom: 2rem;
}